/**
 * Created by gantushig on 2/18/20.
 */
.header3 {
    height: 40px;
    color:white;
    margin-top:10px;
    margin-bottom:20px;
    font-size: 22px;
    font-weight: 900;
    background-color: #f26334;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}