
.dropdown-toggle-home {
  white-space: nowrap;
  font-size: 16px;
}

.dropdown-toggle-home::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropdown-toggle-home:empty::after {
  margin-left: 0;
}

.featured-blue {
  h4 {
    color:white;
  }
  a.navbar-item  {
    color:white;
  }
}
//
//.navbar {
//  ul {
//    position: absolute;
//    right: 50px;
//    top: 14px;
//  }
//}
//
//.navbar-default {
//  top: -70px; /* you'll have to figure out the exact number here */
//}
//
//.navbar-default, .navbar-fixed-bottom {
//  position: relative; /* this can also be static */
//}
a[target="_blank"]:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}