/**
 * Created by gantushig on 2/18/20.
 */

//#f26334
//#002c6c
table.tb2 {

  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid gray;
  color: #000000;
  font-size: 12px;
  thead {
    background-color: #f26334;
    color: #FFFFFF;
    th {
      height: 40px;
    }
  }
  thead th:nth-child(1) {
    width: 20%;
  }
  thead th:nth-child(2) {
    width: 20%;
  }
  thead th:nth-child(3) {
    width: 35%;
  }
  thead th:nth-child(4) {
    width: 25%;
  }
  tr {
    border: solid;
    border-width: 1px 0;
    border-color: silver;
  }
  th {

  }

  th, td {
    b {
      color:#f26334;
    }
      border-right: solid 1px silver;
      border-left: solid 1px silver;

    padding: 5px;
    p {
      font-size: 12px;

    }
  }
}

h2 {
  margin: 20px;
}
.parent {
  margin: auto;
  display: flex;
  height: 80%;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.child {
  margin: auto;  /* Magic! */
  max-width: 100%;
  max-height: 100%;
}