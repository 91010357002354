body {
  font-family:  "Gotham Pro", "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
}
.toolkit {
  font-size: 16px;
  font-weight: 300;
}
.toolkit figure {
  display: inline-block;
  vertical-align: top;
}
.toolkit figcaption {
  margin-top: 5px;
  text-align: center;
}
.toolkit .thin {
  font-weight: 200 !important;
}
.toolkit p {
  font-size: 16px;
  line-height: 24px;
  color: #454545;
}
.toolkit p.small {
  font-size: 14px;
  line-height: 24px;
}
.toolkit p.large {
  font-size: 18px;
  line-height: 26px;
}
.toolkit strong {
  font-weight: 500;
}
.toolkit li {
  line-height: 24px;
}
.toolkit ul.list-left {
  position: relative;
  left: -20px;
}
.toolkit ul.downloads {
  padding-left: 38px;
}
.toolkit ul.downloads li {
  line-height: 200%;
  list-style: none;
  position: relative;
}
.toolkit ul.downloads li a {
  font-weight: normal;
}
.toolkit ul.downloads li:before {
  color: #008dbd;
  content: '\e61d';
  left: -38px;
  top: 7px;
  position: absolute;
}
.toolkit h1,
.toolkit h2,
.toolkit h3,
.toolkit h4,
.toolkit h5,
.toolkit h6 {
  color: #002c6c;
  font-weight: 400;
}
.toolkit section h1:first-child,
.toolkit section h2:first-child,
.toolkit section h3:first-child,
.toolkit section h4:first-child,
.toolkit section h5:first-child,
.toolkit section h6:first-child {
  margin-top: 0;
}
.toolkit h1 {
  font-weight: 400;
  margin: 20px 0 20px;
}
.toolkit h3 {
  margin-top: 45px;
}
.toolkit h4 {
  margin: 35px 0 15px;
  font-size:18px;
}
.toolkit h5 {
  margin-top: 20px;
}
.toolkit .heading-hr {
  border-bottom: 1px solid #b1b3b3;
  margin-bottom: 20px;
}
.toolkit .subhead {
  margin-top: 0;
}
.toolkit a {
  color: #008dbd;
  text-decoration: none;
  font-weight: 500;
}
.toolkit a:hover,
.toolkit a:focus {
  color: #002c6c;
}
.toolkit .gradient {
  margin-top: 0px;
  width: 100%;
  background: #e9f4fb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e9f4fb 0%, #ffffff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e9f4fb), color-stop(100%, #ffffff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #e9f4fb 0%, #ffffff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #e9f4fb 0%, #ffffff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #e9f4fb 0%, #ffffff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #e9f4fb 0%, #ffffff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e9f4fb', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  background-repeat: repeat-x;
  background-size: auto 43px;
}
.toolkit .logo img {
  margin-right: 5px;
  height: 43px;
  width: auto;
}
@media only screen and (min-width: 768px) {
  .toolkit .logo img {
    height: 61px;
    margin-right: 30px;
  }
}
.toolkit .logo {
  float: left;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .toolkit .logo {
    border-bottom: none;
  }
}
.toolkit .logo p,
.toolkit .logo h4 {
  display: inline-block;
}
@media only screen and (max-width: 992px) {
  .toolkit .logo p,
  .toolkit .logo h4 {
    font-size: 16px;
  }
}
.toolkit .logo1 {
  position: relative;
  float: left;
  margin-top: 15px;
}
.toolkit .logo1 p {
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: 42px;
  left: 103px;
  width: 300px;
}
@media only screen and (min-width: 768px) {
  .toolkit .logo1 {
    margin-top: 0px;
    padding-bottom: 31px;
    padding-top: 39px;
  }
}
.toolkit .secondary-nav {
  display: none;
  font-size: 12px;
}
.toolkit .secondary-nav .form-search-box {
  vertical-align: top;
  font-size: 14px;
}
.toolkit .secondary-nav .login-button {
  position: relative;
  vertical-align: top;
}
.toolkit .secondary-nav .btn-group {
  margin-left: 30px;
}
@media only screen and (min-width: 768px) {
  .toolkit .secondary-nav {
    display: block;
    float: right;
    margin-top: 35px;
    text-align: right;
  }
  .toolkit .secondary-nav ul.list-inline {
    margin-bottom: 5px;
    margin-left: 0;
  }
  .toolkit .secondary-nav ul.list-inline li {
    padding: 0;
  }
  .toolkit .secondary-nav ul.list-inline li.language a {
    color: #454545;
  }
  .toolkit .secondary-nav ul.list-inline li.language a:hover {
    color: #002c6c;
  }
  .toolkit .secondary-nav ul.list-inline li a {
    border-right: 1px solid #b1b3b3;
    padding-right: 5px;
  }
  .toolkit .secondary-nav ul.list-inline li:last-of-type a {
    border-right: 0;
    padding-right: 0;
  }
  .toolkit .secondary-nav .form-search-box {
    display: inline-block;
  }
  .toolkit .secondary-nav .login-button {
    margin-left: 15px;
    padding-left: 35px;
    padding-right: 15px;
  }
  .toolkit .secondary-nav .login-button span[class^=icon] {
    font-size: 18px;
    left: 12px;
    position: absolute;
    top: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .toolkit .secondary-nav .login-button {
    margin-left: 30px;
    padding-left: 70px;
    padding-right: 42px;
  }
  .toolkit .secondary-nav .login-button span[class^=icon] {
    font-size: 18px;
    left: 40px;
    position: absolute;
    top: 8px;
  }
}
.toolkit .page-flag,
.toolkit .page-flag-small {
  display: none;
  position: absolute;
}
@media only screen and (min-width: 768px) {
  .toolkit .page-flag,
  .toolkit .page-flag-small {
    background-color: #b1b3b3;
    display: block;
    top: 0;
    right: 0;
    padding: 30px 25px 15px 25px;
    margin-right: 15px;
    position: absolute;
  }
}
.toolkit .page-flag h4,
.toolkit .page-flag-small h4 {
  color: #fff;
  margin: 0;
}
.toolkit .page-flag-small {
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .toolkit .page-flag-small {
    padding: 3px 20px;
  }
}
.toolkit .logo1 h5 {
  display: inline;
}
.toolkit .toolbox {
  padding: 55px 0;
}
.toolkit .toolkit-title {
  text-align: center;
}
.toolkit .toolkit-title > h1 {
  margin-top: 0;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-title > h1 {
    font-size: 30px;
  }
}
.toolkit .toolkit-buttons a {
  vertical-align: top;
}
.toolkit .toolkit-bottom-container {
  background-color: #f2fafc;
  padding-bottom: 50px;
}
.toolkit .toolkit-bottom-message h1 {
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-message h1 {
    margin-top: 30px;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-code,
.toolkit .toolkit-bottom-blocks .toolkit-bottom-styleguide {
  margin-top: 65px;
  float: left;
  background: url('../images/plum.gif') #ffffff repeat-x;
  box-shadow: 0 -5px 10px #e6e6e6;
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-code {
  margin-right: 60px;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks .toolkit-bottom-code {
    margin-right: 0;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-code .circle {
  float: left;
  width: 7px;
  height: 7px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 7px;
  text-align: center;
  background: #fff;
  margin-left: 4px;
  margin-top: 4px;
}
.toolkit .toolkit-bottom-blocks-container {
  float: left;
  margin-right: 130px;
  width: 440px;
}
@media only screen and (max-width: 1200px) {
  .toolkit .toolkit-bottom-blocks-container {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks-container {
    margin-right: 0px;
    width: auto;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-code,
.toolkit .toolkit-bottom-blocks .toolkit-bottom-styleguide {
  width: 440px;
  height: 232px;
  margin-bottom: 38px;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks .toolkit-bottom-code,
  .toolkit .toolkit-bottom-blocks .toolkit-bottom-styleguide {
    width: 100%;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-code .toolkit-block1 {
  margin-top: 28px;
  margin-left: 32px;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks .toolkit-bottom-code .toolkit-block1 {
    margin-right: 36px;
    margin-left: 10px;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-bottom-styleguide .toolkit-block2 {
  margin-top: 42px;
  margin-left: 116px;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks .toolkit-bottom-styleguide .toolkit-block2 img {
    position: relative;
    left: -75px;
  }
}
.toolkit .toolkit-bottom-blocks .toolkit-block-copy {
  height: 485px;
}
@media only screen and (max-width: 992px) {
  .toolkit .toolkit-bottom-blocks .toolkit-block-copy {
    height: auto;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks .toolkit-block-copy h4 {
    clear: both;
  }
}
.toolkit .toolkit-bottom-blocks-container .toolkit-style-guide a {
  float: left;
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks-container .toolkit-download {
    position: relative;
    top: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .toolkit-bottom-blocks-container .toolkit-download,
  .toolkit .toolkit-bottom-blocks-container .toolkit-style-guide {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .toolkit-bottom-blocks-container .toolkit-download,
  .toolkit .toolkit-bottom-blocks-container .toolkit-style-guide {
    margin-top: 20px;
  }
}
.toolkit .header-top {
  background-color: #f4f4f4;
  border-bottom: 1px solid #b1b3b3;
  display: none;
}
@media only screen and (min-width: 768px) {
  .toolkit .header-top {
    display: block;
  }
}
.toolkit .header-bottom {
  background-color: #888b8d;
  color: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.toolkit header {
  box-shadow: 0 -18px 30px 26px rgba(0, 0, 0, 0.05);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
}
.toolkit header .container {
  position: relative;
}
.toolkit header.no-gradient {
  box-shadow: none;
}
.toolkit.header-short .logo1 p {
  top: 38px;
}
@media only screen and (min-width: 768px) {
  .toolkit.header-short header:not(.example-header) .logo1 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
.toolkit .navigation {
  background-color: #fff;
}
.toolkit .navbar {
  margin-bottom: 0;
}
.toolkit .navbar li {
  left: 0px;
}
.toolkit .navbar-default {
  background-color: #fff;
  border: 0;
}
.toolkit .navbar-default .navbar-mover li > a {
  color: #002c6c;
}
.toolkit .navbar-default .navbar-toggle .icon-bar {
  background-color: #f26334;
  width: 30px;
  height: 5px;
}
.toolkit .navbar-default .navbar-toggle.collapsed .icon-bar {
  background-color: #002c6c;
}
.toolkit .navbar-toggle {
  border: none;
}
.toolkit .navbar-toggle:hover {
  background: none;
}
@media only screen and (min-width: 992px) {
  .toolkit .navbar-default .navbar-nav > li.active {
    background: url('../images/nav-select.gif') repeat-x;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMXB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDEgOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMSA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHJlY3QgZmlsbD0iI0YyNjMzNCIgd2lkdGg9IjEiIGhlaWdodD0iOCIvPgo8L3N2Zz4=) repeat-x;
  }
}
.toolkit .navbar-default .navbar-nav > li > a:hover {
  color: #f26334;
}
.toolkit .navbar-default .navbar-nav > .active > a,
.toolkit .navbar-default .navbar-nav > .active > a:hover,
.toolkit .navbar-default .navbar-nav > .active > a:focus {
  color: #f26334;
}
.toolkit .navbar-nav > li > a {
  margin-top: 4px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .toolkit .navbar-nav > li > a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .navbar-nav > li > a {
    margin: 0 !important;
  }
}
.toolkit .navbar-default .navbar-nav {
  width: 100%;
}
.toolkit .navbar-default .navbar-nav > li {
  margin-right: 20px;
}
@media only screen and (min-width: 1200px) {
  .toolkit .navbar-default .navbar-nav > li {
    margin-right: 45px;
  }
}
@media only screen and (min-width: 768px) {
  .toolkit .navbar-default .navbar-nav > li {
    margin-left: 30px;
  }
}
.toolkit .navbar-default {
  z-index: 3500;
}
.toolkit .navbar-default .navbar-nav > .active > a {
  background: none;
}
.toolkit .navbar-static-top,
.toolkit .navbar-fixed-top,
.toolkit .navbar-fixed-bottom {
  border-radius: 0;
}
.toolkit .navbar-fixed-top {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .toolkit .navbar-fixed-top {
    position: fixed;
  }
}
.toolkit .yamm.navbar {
  border: none;
}
.toolkit .yamm .navbar-nav {
  margin: 0;
}
.toolkit .yamm .navbar-nav .section li {
  font-size: 14px;
  margin-left: 0;
  margin-right: 0;
}
.toolkit .yamm .navbar-nav .section li > a {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}
.toolkit .yamm .navbar-nav .section li > a::focus {
  outline-color: #b1b3b3;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-nav .section li > a.dropdown-toggle {
    padding-right: 8px;
  }
}
.toolkit .yamm .navbar-nav > li {
  border-bottom: 1px solid #b1b3b3;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-nav > li {
    border-bottom: none;
    font-size: 16px;
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) {
  .toolkit .yamm .navbar-nav > li {
    margin-right: 45px;
  }
}
.toolkit .yamm .navbar-nav > li:last-of-type {
  border-bottom: none;
  margin-right: 0;
}
.toolkit .yamm .navbar-nav > li > a,
.toolkit .yamm .navbar-nav > li > a:active {
  color: #002c6c;
  font-size: 18px;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-nav > li > a,
  .toolkit .yamm .navbar-nav > li > a:active {
    padding-bottom: 0;
    font-size: 16px;
  }
}
.toolkit .yamm .navbar-nav > li.dropdown > a {
  padding-right: 8px;
}
.toolkit .yamm .navbar-nav a:hover {
  color: #f26334;
}
.toolkit .yamm .navbar-nav a:focus {
  outline-color: #b1b3b3;
}
.toolkit .yamm .navbar-nav a.btn-primary:hover {
  color: #fff;
}
.toolkit .yamm .navbar-nav .active .section li > a,
.toolkit .yamm .navbar-nav .active .section li > a:hover,
.toolkit .yamm .navbar-nav .active .section li > a:active {
  background-color: transparent;
}
.toolkit .yamm .navbar-nav .secondary-nav {
  display: block;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-nav .secondary-nav {
    display: none;
  }
}
.toolkit .yamm .navbar-nav .nav-divider {
  background-color: transparent;
  border-top: 1px solid #b1b3b3;
  height: auto;
  margin: 0;
  overflow: auto;
}
.toolkit .yamm p {
  font-size: 14px;
  line-height: 20px;
}
@media only screen and (min-width: 992px) {
  .toolkit .yamm p {
    font-size: 16px;
    line-height: 24px;
  }
}
.toolkit .yamm .navbar-header {
  background-color: #f4f4f4;
  border-bottom: 1px solid #b1b3b3;
  margin-left: -15px;
  margin-right: -15px;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-header {
    display: none;
  }
}
.toolkit .yamm .navbar-header .logo1 {
  margin-top: 12px;
  margin-left: 15px;
}
.toolkit .yamm .navbar-header .secondary-nav {
  display: inline-block;
  float: right;
  margin-top: 28px;
}
.toolkit .yamm .navbar-header .secondary-nav .search {
  color: #f26334;
  padding: 0px 10px;
}
.toolkit .yamm .navbar-header .secondary-nav .search span {
  font-size: 24px;
}
.toolkit .yamm .navbar-header .secondary-nav .login-button {
  padding: 3px 5px;
}
.toolkit .yamm .navbar-header .secondary-nav .login-button [class^='icon'] {
  font-size: 12px;
}
.toolkit .yamm .navbar-collapse {
  border-top: 0;
  margin: 7px 0;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .navbar-collapse {
    margin: 0;
  }
}
.toolkit .yamm.navbar-default {
  z-index: 3000;
}
.toolkit .yamm.navbar-default .navbar-nav {
  padding-bottom: 15px;
  overflow-x: hidden;
}
.toolkit .yamm.navbar-default .navbar-nav li.open > a,
.toolkit .yamm.navbar-default .navbar-nav li.open > a:hover {
  background-color: transparent;
}
.toolkit .yamm .navbar-toggle,
.toolkit .yamm .navbar-toggle:focus {
  background-color: transparent;
  border: 0;
  margin-bottom: 20px;
  margin-right: 5px;
  margin-top: 20px;
}
.toolkit .yamm .logo {
  background-color: #f4f4f4;
  height: 140px;
  padding-top: 35px;
}
.toolkit .yamm .dropdown-toggle,
.toolkit .yamm .dropdown-toggle:hover {
  background-color: transparent;
  border: none;
  height: auto;
}
.toolkit .yamm .dropdown-toggle {
  padding-left: 0;
  padding-right: 8px;
  position: relative;
}
.toolkit .yamm .dropdown-toggle::after {
  font-family: gs1_icons;
  content: "\e601";
  position: absolute;
  right: 0;
  -webkit-transition: 250ms -webkit-transform;
  transition: 250ms transform;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .dropdown-toggle::after {
    font-size: .9em;
    position: relative;
    right: -8px;
    top: 2px;
    transform: rotate(0);
  }
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .open .dropdown-toggle {
    color: #f26334;
  }
}
.toolkit .yamm .open .dropdown-toggle::after {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
@media only screen and (min-width: 992px) {
  .toolkit .yamm .open .dropdown-toggle::before {
    background: url(../images/nav-arrow-down.png) no-repeat transparent top center;
    bottom: -12px;
    content: '';
    height: 12px;
    left: 0px;
    position: absolute;
    width: 100%;
    z-index: 4000;
  }
}
.toolkit .yamm .dropdown-menu {
  border: none;
  padding: 10px 10px 20px;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .dropdown-menu {
    background-color: #f4f4f4;
    padding: 30px 45px 20px 45px;
  }
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .dropdown-menu {
    box-shadow: 0px 5px 25px -6px #cccccc;
    box-shadow: 0px 9px 25px -6px rgba(0, 0, 0, 0.4);
  }
}
.toolkit .yamm .dropdown-menu h4 {
  margin-left: 0;
}
.toolkit .yamm h4 {
  font-size: 16px;
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm h4 {
    font-size: 14px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .toolkit .yamm h4 {
    font-size: 18px;
  }
}
.toolkit .yamm h4 a {
  color: #002c6c;
}
.toolkit .yamm h4 a:hover,
.toolkit .yamm h4 a:focus {
  background-color: transparent;
}
.toolkit .yamm h4:first-of-type {
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm h4 + h4 {
    border-top: 1px solid #b1b3b3;
    margin-top: 15px;
    padding-top: 15px;
  }
}
.toolkit .yamm .section {
  margin: 0 15px 15px 0;
  padding: 0 0 15px 0;
}
@media only screen and (min-width: 992px) {
  .toolkit .yamm .section {
    padding-right: 0;
    margin: 0 0px 15px 0;
  }
}
.toolkit .yamm .section li {
  list-style-type: none;
}
.toolkit .yamm .section a {
  display: inline-block;
  line-height: 20px;
  padding-top: 6px;
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .section {
    border-bottom: 1px solid #b1b3b3;
  }
  .toolkit .yamm .section:last-of-type {
    border-bottom: none;
  }
  .toolkit .yamm .section:last-of-type + h4 {
    border-top: 1px solid #b1b3b3;
    margin-top: -15px;
    padding-top: 15px;
  }
}
.toolkit .yamm .return-padding {
  padding-left: 10px;
  padding-right: 10px;
}
@media only screen and (min-width: 768px) {
  .toolkit .yamm .return-padding {
    padding-left: 45px;
    padding-right: 45px;
  }
}
.toolkit .yamm .mega-menu-module {
  background-color: #fff;
  margin-bottom: 30px;
}
.toolkit .yamm .mega-menu-module:last-of-type {
  margin-bottom: 0;
}
.toolkit .site-content {
  margin-top: 0;
}
@-moz-document url-prefix() {
  .site-content {
    margin-top: 190px;
  }
}
.toolkit .site-content .typeface {
  font-size: 57px;
  color: #00b6de;
  font-weight: 100;
  word-wrap: break-word;
}
.toolkit .section-spacing {
  margin-top: 50px;
  border-bottom: 1px solid #b1b3b3;
  padding-bottom: 15px;
}
@media only screen and (max-width: 992px) {
  .toolkit .section-spacing:first-of-type {
    margin-top: 30px;
  }
}
.toolkit .section-spacing > h1 {
  margin-bottom: 30px;
}
.toolkit .section-spacing h4 {
  margin-top: 30px;
  font-weight: 400;
}
.toolkit .section-divider {
  border-bottom: 1px solid #b1b3b3;
}
.toolkit section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.toolkit .typography-heading-container div {
  overflow: hidden;
}
.toolkit .typography-heading-container .typography-heading > h4,
.toolkit .typography-heading-container .typography-example > h1,
.toolkit .typography-heading-container .typography-example > h2,
.toolkit .typography-heading-container .typography-example > h3,
.toolkit .typography-heading-container .typography-example > h4,
.toolkit .typography-heading-container .typography-example > h5,
.toolkit .typography-heading-container .typography-example > h6 {
  margin-top: 40px;
}
.toolkit .typography-heading-container .typography-example {
  margin-bottom: 30px;
  min-height: 62px;
}
@media only screen and (max-width: 768px) {
  .toolkit .typography-heading-container .typography-example {
    min-height: 33px;
    margin-bottom: 10px;
  }
}
.toolkit .typography-heading-container .typography-example .typography-anchors {
  float: left;
  margin-right: 35px;
  font-weight: 500;
}
.toolkit .typography-heading-container .typography-example .pointer2 {
  position: relative;
}
.toolkit .typography-heading-container .typography-example .pointer1 {
  position: absolute;
  width: 17px;
  margin-left: 200px;
  margin-top: 9px;
  cursor: pointer;
}
.toolkit .typography-heading-container .typography-example .link-active {
  color: #002c6c;
}
.toolkit .typography-heading-container .typography-heading {
  float: left;
  width: 196px;
}
@media only screen and (max-width: 768px) {
  .toolkit .typography-heading-container .typography-heading {
    float: none;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .typography-heading-container .typography-heading {
    width: 126px;
  }
}
.toolkit .typography-heading-container .typography-heading2 {
  float: left;
}
@media only screen and (max-width: 992px) {
  .toolkit .typography-heading-container .typography-heading2 {
    float: none;
  }
}
.toolkit .typography-heading-container .typography-heading2 + .typography-example {
  margin-bottom: 5px;
}
.toolkit .typography-heading-container .typography-floater {
  float: left;
  margin-right: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
}
.toolkit .typography-blockquote {
  position: relative;
  border-top: 1px solid #b1b3b3;
  border-bottom: 1px solid #b1b3b3;
  border-left: 0;
  margin: 30px 0;
  padding-top: 22px;
  padding-bottom: 32px;
  padding-right: 22px;
  font-size: 24px;
  color: #002c6c;
}
.toolkit .typography-blockquote cite {
  position: absolute;
  right: 30px;
  bottom: 10px;
  font-size: 16px;
}
.toolkit .blockquote-circle {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  font-size: 45px;
  line-height: 57px;
  text-align: center;
  background: #f4f4f4;
  color: #f26334;
  display: inline-block;
  vertical-align: top;
}
.toolkit blockquote:before,
.toolkit blockquote:after {
  content: none;
}
.toolkit .iconography-icons {
  padding-top: 20px;
  padding-bottom: 62px;
}
.toolkit .iconography-icons span {
  font-size: 100px;
  line-height: 120px;
  margin-right: 50px;
  color: #f26334;
}
.toolkit .set-contents div {
  float: left;
  width: 25%;
}
@media only screen and (max-width: 992px) {
  .toolkit .set-contents div {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .set-contents div {
    width: 100%;
  }
}
.toolkit .dropdown-menu {
  border-radius: 0;
  border-top: 0;
  margin-top: 0px;
  box-shadow: none;
  width: 100%;
  padding-bottom: 15px;
  z-index: 3100;
  text-align: left;
}
.toolkit .dropdown-menu a {
  color: #008dbd;
  white-space: normal;
}
.toolkit .dropdown-menu a:hover,
.toolkit .dropdown-menu a:focus {
  background-color: #fff;
  color: #002c6c;
}
.toolkit .dropdown-menu h4 {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
}
.toolkit .dropdown-toggle,
.toolkit .btn-group.open .dropdown-toggle {
  text-align: left;
  width: 100%;
  color: #fff;
  background-color: #002c6c;
  box-shadow: none;
}
.toolkit .dropdown-toggle,
.toolkit .btn-group.open .dropdown-toggle,
.toolkit .dropdown-toggle.open,
.toolkit .btn-group.open .dropdown-toggle.open,
.toolkit .dropdown-toggle:hover,
.toolkit .btn-group.open .dropdown-toggle:hover {
  border: 1px solid #b1b3b3;
}
.toolkit .dropdown-toggle .icon-arrow_down {
  color: #f26334;
  padding-left: 15px;
  font-size: 16px;
  position: relative;
  top: 3px;
}
.toolkit a.btn-primary,
.toolkit button.btn-primary,
.toolkit *.btn-primary {
  background-color: #f26334;
}
.toolkit a.btn-primary-active,
.toolkit button.btn-primary-active,
.toolkit *.btn-primary-active,
.toolkit a.btn-primary:hover,
.toolkit button.btn-primary:hover,
.toolkit *.btn-primary:hover {
  background-color: #cc3c0d;
}
.toolkit a.btn-primary-disabled,
.toolkit button.btn-primary-disabled,
.toolkit *.btn-primary-disabled {
  background-color: #f8ad94;
}
.toolkit a[role=button] + a[role=button] {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .toolkit a[role=button] + a[role=button] {
    margin-top: 0;
  }
}
.toolkit a.btn-primary[role=button],
.toolkit a.btn-secondary[role=button],
.toolkit a.btn-primary[role=menuitem],
.toolkit a.btn-secondary[role=menuitem] {
  display: inline-block;
}
.toolkit a.btn-primary[role=button]:hover {
  color: #fff;
}
.toolkit a.btn-download {
  line-height: 120%;
  padding-right: 64px;
}
.toolkit .elements-buttons .buttons-section > p {
  font-weight: 400;
  margin-bottom: 0;
  display: block;
}
.toolkit .elements-buttons p.btn-orange {
  color: #f26334;
  margin-top: 30px;
}
.toolkit .btn {
  border-radius: 0;
  padding: 0;
}
.toolkit .btn-primary,
.toolkit .btn-secondary {
  padding: 9px 16px 8px;
}
.toolkit a.btn-primary,
.toolkit button.btn-primary,
.toolkit div.btn-primary,
.toolkit a.btn-primary:hover,
.toolkit button.btn-primary:hover,
.toolkit div.btn-primary:hover,
.toolkit a.btn-primary:active,
.toolkit button.btn-primary:active,
.toolkit div.btn-primary:active {
  color: #ffffff;
  border: 0;
}
.toolkit a.btn-secondary,
.toolkit button.btn-secondary,
.toolkit div.btn-secondary,
.toolkit a.btn-secondary:hover,
.toolkit button.btn-secondary:hover,
.toolkit div.btn-secondary:hover,
.toolkit a.btn-secondary:active,
.toolkit button.btn-secondary:active,
.toolkit div.btn-secondary:active {
  color: #f26334;
  border: 1px solid #b1b3b3;
  background-color: #ffffff;
}
.toolkit .btn-secondary:hover,
.toolkit .btn-secondary-active {
  color: #cc532b;
}
.toolkit .btn-secondary:disabled {
  color: #f8ad94 !important;
}
.toolkit .btn-margin {
  margin-top: 15px;
  margin-right: 15px;
}
.toolkit .btn-lg {
  font-size: 18px;
  border-radius: 0;
}
.toolkit .btn-sm {
  padding: 10px;
  font-size: 14px;
  border-radius: 0;
}
.toolkit .btn-background {
  color: transparent;
  padding: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 992px) {
  .toolkit .btn-background {
    width: 80%;
    margin-top: 15px;
  }
}
.toolkit .btn-background p {
  color: #fff;
}
.toolkit .btn-background button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.toolkit .btn-navy,
.toolkit .btn-navy-active,
.toolkit .btn-navy-active:hover,
.toolkit .btn-navy:hover,
.toolkit .btn-navy:disabled {
  background-color: #002c6c;
  color: #fff;
}
.toolkit .btn-icon {
  position: relative;
}
.toolkit .btn-icon [class^="icon-"],
.toolkit .btn-icon [class*=" icon-"] {
  font-size: 2em;
  position: absolute;
  top: 12px;
  right: 18px;
  color: #002c6c;
}
.toolkit .form-control {
  border-radius: 0;
  box-shadow: none;
  height: 34px;
  margin: 0px;
}
.toolkit select.form-control,
.toolkit textarea.form-control {
  height: auto;
}
.toolkit input.form-control,
.toolkit textarea.form-control,
.toolkit select.form-control {
  border: 1px solid #b1b3b3;
}
.toolkit .form-control-feedback {
  top: 0px;
}
.toolkit .form-search-box > div {
  float: left;
  height: 34px;
}
.toolkit .form-search-box input {
  padding-left: 30px;
}
.toolkit .form-search-box .search-glyph2 {
  position: absolute;
}
.toolkit .form-search-box .search-glyph1 {
  position: relative;
}
.toolkit .form-search-box .btn-search {
  height: 34px;
  position: relative;
  left: 0;
  background: #fff;
  border: 1px solid #b1b3b3;
  padding: 5px;
  color: #f26334;
  border-left: 0;
  padding-right: 8px;
  padding-left: 7px;
  font-size: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.toolkit .form-search-box .input-wrapper {
  position: relative;
}
.toolkit .form-search-box .input-wrapper:before {
  position: absolute;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  left: 0;
  line-height: 1;
  color: #b1b3b3;
  margin-top: 9px;
  margin-left: 9px;
  top: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toolkit .has-success .form-control-feedback {
  color: #5a8f32;
}
.toolkit .has-error .form-control-feedback {
  color: #cc532b;
}
.toolkit .alert {
  border: 0;
  border-radius: 0;
  background-image: none;
}
.toolkit .alert p {
  display: block;
  font-size: 18px;
  font-weight: 400;
}
.toolkit .alert .close {
  margin-left: 12px;
}
.toolkit .alert-danger {
  background-color: #fce0d6;
}
.toolkit .alert-danger p {
  color: #cc532b;
}
.toolkit .alert-warning {
  background-color: #feefd6;
}
.toolkit .alert-warning p {
  color: #bd7e26;
}
.toolkit .alert-info {
  background-color: #ccedf5;
}
.toolkit .alert-info p {
  color: #007d99;
}
.toolkit .alert-success {
  background-color: #e4f3d9;
}
.toolkit .alert-success p {
  color: #5a8f32;
}
.toolkit .tooltips-section {
  width: 100%;
  margin-top: 25px;
}
.toolkit .tooltips-section > div {
  float: left;
  padding: 5px;
}
@media only screen and (max-width: 992px) {
  .toolkit .tooltips-section > div {
    float: none;
    text-align: center;
  }
}
.toolkit .tooltip-inner {
  max-width: 200px;
  padding: 12px;
  color: #ffffff;
  text-align: left;
  text-decoration: none;
  background-color: #454545;
  border-radius: 0;
  display: block;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
}
.toolkit .tooltip.top {
  margin-top: -7px;
  padding: 5px 0;
}
.toolkit .tooltip.right {
  margin-left: 7px;
  padding: 0 5px;
}
.toolkit .tooltip.bottom {
  margin-top: 7px;
  padding: 5px 0;
}
.toolkit .tooltip.left {
  margin-left: -7px;
  padding: 0 5px;
}
.toolkit .tooltip.top .tooltip-arrow {
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
  border-width: 10px 10px 0;
  border-top-color: #454545;
}
.toolkit .tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 10px 10px 0;
  border-top-color: #454545;
}
.toolkit .tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 10px 10px 0;
  border-top-color: #454545;
}
.toolkit .tooltip.right .tooltip-arrow {
  top: 50%;
  left: -5px;
  margin-top: -9px;
  border-width: 10px 10px 10px 0;
  border-right-color: #454545;
}
.toolkit .tooltip.left .tooltip-arrow {
  top: 50%;
  right: -5px;
  margin-top: -9px;
  border-width: 10px 0 10px 10px;
  border-left-color: #454545;
}
.toolkit .tooltip.bottom .tooltip-arrow {
  top: -5px;
  left: 50%;
  margin-left: -9px;
  border-width: 0 10px 10px;
  border-bottom-color: #454545;
}
.toolkit .tooltip.bottom-left .tooltip-arrow {
  top: -5px;
  left: 5px;
  border-width: 0 10px 10px;
  border-bottom-color: #454545;
}
.toolkit .tooltip.bottom-right .tooltip-arrow {
  top: -5px;
  right: 5px;
  border-width: 0 10px 10px;
  border-bottom-color: #454545;
}
.toolkit .form-group #inputError2 {
  background-color: #fce0d6;
  color: #cc532b;
}
.toolkit .alert {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.toolkit .form-date-picker input {
  border-right: 0;
}
.toolkit .input-group-addon {
  padding: 0 10px 0 0;
  font-size: 22px;
  color: #b1b3b3;
  text-align: center;
  background-color: #fff;
  border: 1px solid #b1b3b3;
  border-left: 0;
  border-radius: 0;
  position: relative;
  left: 0;
  top: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.toolkit .table-striped > tbody > tr:nth-child(odd) > td,
.toolkit .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5;
}
.toolkit .table-hover > tbody > tr:hover > td,
.toolkit .table-hover > tbody > tr:hover > th {
  background-color: #ddd;
}
.toolkit .inside-dividers tr,
.toolkit .inside-dividers li {
  border-bottom: 1px solid #f4f4f4;
}
.toolkit .inside-dividers tr:last-child,
.toolkit .inside-dividers li:last-child {
  border-bottom: none;
}
.toolkit .nav-tabs {
  border-bottom: 1px solid #b1b3b3;
  margin-bottom: 0;
  padding-left: 20px;
  font-weight: 400;
}
.toolkit .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.toolkit .nav-tabs > li > a,
.toolkit .nav-pills > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 0;
}
.toolkit .nav-tabs > li > a:hover,
.toolkit .nav-pills > li > a:hover {
  background-color: transparent;
  border-bottom: 0;
  border-color: transparent;
}
.toolkit .nav-tabs > li.active > a,
.toolkit .nav-tabs > li.active > a:hover,
.toolkit .nav-tabs > li.active > a:focus,
.toolkit .nav-pills > li.active > a,
.toolkit .nav-pills > li.active > a:hover,
.toolkit .nav-pills > li.active > a:focus {
  color: #002c6c;
  background: #ffffff url(../images/nav-select.gif) repeat-x 0 -5px;
  border: 1px solid #b1b3b3;
  border-bottom-color: #fff;
  border-top: 1px solid transparent;
  cursor: default;
}
.toolkit .nav-pills > li.active > a,
.toolkit .nav-pills > li.active > a:hover,
.toolkit .nav-pills > li.active > a:focus {
  border-bottom-color: #b1b3b3;
}
.toolkit .nav-pills ul {
  border-bottom: 0;
}
.toolkit .nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}
.toolkit .nav-tabs.nav-justified > li {
  float: none;
}
.toolkit .nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}
.toolkit .nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 768px) {
  .toolkit .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .toolkit .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}
.toolkit .nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px;
}
.toolkit .nav-tabs.nav-justified > .active > a,
.toolkit .nav-tabs.nav-justified > .active > a:hover,
.toolkit .nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #dddddd;
}
@media (min-width: 768px) {
  .toolkit .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #dddddd;
    border-radius: 4px 4px 0 0;
  }
  .toolkit .nav-tabs.nav-justified > .active > a,
  .toolkit .nav-tabs.nav-justified > .active > a:hover,
  .toolkit .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #ffffff;
  }
}
.toolkit .tab-content .tab-pane {
  padding: 20px;
  background-color: #ffffff;
}
.toolkit .tab-content {
  border: 1px solid #b1b3b3;
  border-top: 0;
}
.toolkit .tab-content > .active {
  display: inline-block;
}
.toolkit .panel-heading {
  padding-bottom: 15px;
}
.toolkit .panel-title > a {
  display: inline-block;
  width: 100%;
  height: 18px;
  background: url('../images/plus-minus.png') no-repeat right 1px;
}
.toolkit .panel-title .collapsed {
  background: url('../images/plus-minus.png') no-repeat right -29px;
}
.toolkit .panel-group {
  margin-bottom: 5px;
}
.toolkit .panel-group .panel {
  border-bottom: 1px solid #b1b3b3;
}
.toolkit .panel-group .panel + .panel {
  margin-top: 0;
}
.toolkit .panel-group .panel:last-of-type {
  border-bottom: none;
}
.toolkit .carousel p {
  color: #002c6c;
}
.toolkit .carousel-container {
  max-width: 100%;
}
.toolkit .carousel-inner {
  height: 400px;
}
.toolkit .carousel-indicators {
  background: rgba(0, 0, 0, 0.15);
  bottom: 0;
  margin-bottom: 0;
  height: 42px;
  margin-left: 0;
  left: 0;
  width: 100%;
}
.toolkit .carousel-indicators li {
  border-radius: 15px;
  height: 15px;
  width: 15px;
  margin: 10px 5px;
  margin-top: 13px;
}
@media only screen and (min-width: 768px) {
  .toolkit .carousel-indicators li {
    margin: 10px;
    margin-top: 13px;
  }
}
.toolkit .carousel-control {
  position: absolute;
  z-index: 15;
  top: auto;
  height: 42px;
  bottom: 0;
  width: auto;
  opacity: 1;
  font-size: 20px;
  color: #fff;
  text-shadow: none;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-control {
    width: 20%;
  }
}
.toolkit .carousel-control {
  background: none;
}
.toolkit .carousel-control [class^="icon-"],
.toolkit .carousel-control [class*=" icon-"] {
  font-size: 30px;
  font-weight: 700;
  top: 6px;
}
.toolkit .carousel-control .icon-arrow_left {
  font-size: 20px;
  position: relative;
  top: 9px;
  left: 50px;
  margin-left: 0;
}
.toolkit .carousel-control .icon-arrow_right {
  font-size: 20px;
  position: relative;
  top: 9px;
  right: 50px;
  margin-left: 0;
}
.toolkit .carousel-inner > .item {
  height: 100%;
  width: 100%;
  padding: 40px;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-inner > .item {
    padding: 35px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .carousel-inner > .item {
    padding: 25px;
  }
}
.toolkit .carousel-inner > .item h1 {
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-inner > .item h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .carousel-inner > .item h1 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-inner > .item p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .carousel-inner > .item p {
    font-size: 14px;
  }
}
.toolkit .carousel-inner .item p,
.toolkit .carousel-inner .item h1 {
  display: block;
  margin-top: 0;
}
.toolkit .carousel-inner > .item .carousel-glyph {
  width: auto;
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-inner > .item .carousel-glyph {
    display: none;
  }
}
.toolkit .carousel-inner > .item .carousel-glyph span {
  font-size: 200px;
  color: #002c6c;
  margin-right: 50px;
}
.toolkit .carousel-inner > .item .place-hold-image {
  height: 100%;
  width: 200px;
  margin-right: 30px;
  margin-bottom: 10px;
  background: url('http://placehold.it/200x200') no-repeat;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-inner > .item .place-hold-image {
    display: none;
  }
}
.toolkit .carousel-bgimage-right h1,
.toolkit .carousel-bgimage-right p {
  width: 650px;
}
@media only screen and (max-width: 1200px) {
  .toolkit .carousel-bgimage-right h1,
  .toolkit .carousel-bgimage-right p {
    width: 420px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-bgimage-right h1,
  .toolkit .carousel-bgimage-right p {
    width: 100%;
  }
}
.toolkit .carousel-bgimage-right {
  background: url('../images/comp1.jpg') no-repeat transparent right;
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-bgimage-right {
    background: url('../images/comp1b.jpg') no-repeat transparent right;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-bgimage-right {
    background: none;
  }
}
.toolkit .carousel-bgimage {
  background: url('../images/comp2.jpg') no-repeat right;
}
.toolkit .carousel-sidebar {
  height: 358px;
  width: 420px;
  background-color: #f4f4f4;
  position: relative;
  top: -40px;
  left: -40px;
  padding: 50px;
  padding-top: 20px;
}
@media only screen and (max-width: 1200px) {
  .toolkit .carousel-sidebar {
    width: 50%;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .carousel-sidebar {
    display: none;
  }
}
.toolkit .carousel-sidebar a {
  display: block;
  border-bottom: 1px solid #b1b3b3;
  padding: 10px;
  padding-left: 0;
  font-size: 16px;
}
.toolkit .carousel-sidebar a:last-of-type {
  border-bottom: 0;
}
@media only screen and (min-width: 992px) {
  .toolkit .full-width .carousel-control.right {
    right: 30%;
  }
  .toolkit .full-width .carousel-control.left {
    left: 30%;
  }
}
@media only screen and (max-width: 1200px) {
  .toolkit .structure-grid .col-md-2 {
    margin-right: 20px;
    margin-top: 20px;
    float: left;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .structure-grid .col-md-2 {
    float: none;
  }
}
.toolkit .structure-grid.section-spacing,
.toolkit .structure-media-queries.section-spacing {
  padding-bottom: 30px;
}
.toolkit .section-spacing > p.small {
  margin-bottom: 30px;
}
.toolkit .content-module-container.flag-new .content-module::before {
  background-color: #f26334;
  color: #fff;
  content: 'New';
  left: -1px;
  padding: 4px 15px;
  position: absolute;
  top: -18px;
}
.toolkit h1.flag-new::after,
.toolkit h2.flag-new::after,
.toolkit h3.flag-new::after,
.toolkit h4.flag-new::after,
.toolkit h5.flag-new::after,
.toolkit h6.flag-new::after {
  font-size: 14px;
  background-color: #f26334;
  color: #ffffff;
  content: 'New';
  padding: 4px 15px;
  margin-left: 10px;
  vertical-align: middle;
}
.toolkit [class*=btn-] {
  position: relative;
}
.toolkit [class*=btn-].flag-new::before {
  font-size: 12px;
  font-weight: 300;
  background-color: #002c6c;
  color: #ffffff;
  content: 'New';
  padding: 3px 11px;
  position: absolute;
  left: 8px;
  top: -15px;
}
.toolkit .content-module-container {
  margin-bottom: 30px;
}
.toolkit .content-module-container:before,
.toolkit .content-module-container:after {
  content: " ";
  display: table;
}
.toolkit .content-module-container:after {
  clear: both;
}
.toolkit .content-module-wrapper {
  border-top: 7px solid #f26334;
}
.toolkit .content-module,
.toolkit .content-module-freeform {
  background-color: #ffffff;
  border: 1px solid #b1b3b3;
  border-top: 0;
  position: relative;
}
.toolkit .content-module h1,
.toolkit .content-module-freeform h1,
.toolkit .content-module h2,
.toolkit .content-module-freeform h2,
.toolkit .content-module h3,
.toolkit .content-module-freeform h3,
.toolkit .content-module h4,
.toolkit .content-module-freeform h4,
.toolkit .content-module h5,
.toolkit .content-module-freeform h5,
.toolkit .content-module h6,
.toolkit .content-module-freeform h6 {
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #b2b2b8;
}
.toolkit .content-module h1.no-border,
.toolkit .content-module-freeform h1.no-border,
.toolkit .content-module h2.no-border,
.toolkit .content-module-freeform h2.no-border,
.toolkit .content-module h3.no-border,
.toolkit .content-module-freeform h3.no-border,
.toolkit .content-module h4.no-border,
.toolkit .content-module-freeform h4.no-border,
.toolkit .content-module h5.no-border,
.toolkit .content-module-freeform h5.no-border,
.toolkit .content-module h6.no-border,
.toolkit .content-module-freeform h6.no-border {
  border: none;
  padding-bottom: 0;
}
.toolkit .content-module h4,
.toolkit .content-module-freeform h4 {
  font-weight: 400;
}
.toolkit .content-module-freeform {
  padding-left: 0;
  padding-right: 0;
}
.toolkit .content-module-freeform ul,
.toolkit .content-module-freeform ol {
  margin-bottom: 20px;
}
.toolkit .content-module-freeform .module-text {
  padding: 20px;
}
.toolkit .content-module-freeform .module-media {
  padding: 0;
}
.toolkit .content-module a {
  font-weight: 300;
}
.toolkit .content-module p.gray {
  color: #b1b3b3;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .toolkit .content-module p {
    font-size: 14px;
    line-height: 20px;
  }
}
.toolkit .content-module h4 {
  line-height: 26px;
}
.toolkit .content-module h4 a {
  color: #002c6c;
}
.toolkit .content-module ul {
  margin-bottom: 30px;
  list-style: none;
  padding-left: 0;
}
.toolkit .content-module ul:last-child {
  margin-bottom: 0;
}
.toolkit .content-module li {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #b1b3b3;
}
.toolkit .content-module li:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}
.toolkit .content-module ul.standard {
  list-style: disc;
  padding-left: inherit;
  margin-bottom: 1em;
}
.toolkit .content-module ul.standard li {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.toolkit .content-module ul.downloads {
  padding-left: 38px;
}
.toolkit .content-module ul.downloads li {
  padding: 0;
  border-bottom: none;
}
.toolkit .content-module dl {
  border-bottom: 1px solid #b1b3b3;
  padding-bottom: 20px;
}
.toolkit .content-module dl:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}
.toolkit .content-module dt,
.toolkit .content-module dd {
  float: left;
  vertical-align: top;
}
.toolkit .content-module dt {
  color: #b1b3b3;
  font-weight: normal;
  font-size: 11px;
  line-height: 100%;
  width: 20%;
}
.toolkit .content-module dt span {
  display: block;
  font-size: 11px;
  line-height: 100%;
}
.toolkit .content-module dt.large span {
  font-size: 16px;
}
.toolkit .content-module dd {
  width: 76%;
  padding-left: 4%;
}
.toolkit .content-module-padding {
  padding: 20px;
}
.toolkit .centered-image-column img {
  margin: 0 auto;
}
.toolkit .structure-content-modules .col-md-6 {
  float: left;
}
@media only screen and (max-width: 992px) {
  .toolkit .structure-content-modules .col-md-6 {
    float: none;
  }
}
.toolkit .content-module-featured-news {
  padding: 0;
}
.toolkit .content-module-featured-news > div {
  padding: 20px;
}
.toolkit .content-module-featured-news img {
  width: 100%;
}
.toolkit .content-module-featured-news h4 a {
  color: #fff;
}
.toolkit .content-module-3col {
  margin-bottom: 30px;
}
.toolkit .content-module-3col .row div:last-of-type {
  position: relative;
  left: -1px;
}
.toolkit .content-module-3col-img {
  display: none;
}
@media only screen and (min-width: 992px) {
  .toolkit .content-module-3col-img {
    display: block;
  }
}
.toolkit .content-module-divider {
  border-right: 1px solid #b1b3b3;
  border-left: 1px solid #b1b3b3;
  padding: 15px 30px 0px 15px;
}
.toolkit .content-module-divider:nth-of-type(2) {
  border-left: 0;
}
.toolkit .content-module-divider:last-of-type {
  border-right: 0;
}
@media only screen and (max-width: 992px) {
  .toolkit .content-module-divider {
    padding: 30px;
  }
}
.toolkit .panel {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}
.toolkit .panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
}
.toolkit .panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #428bca 0%, #357ebd 100%);
  background-image: linear-gradient(to bottom, #428bca 0%, #357ebd 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff357ebd', GradientType=0);
}
.toolkit .panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
}
.toolkit .panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
}
.toolkit .panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
}
.toolkit .panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
}
.toolkit .panel-default > .panel-heading {
  background-color: #fff;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding-top: 24px;
  background: url(../images/navy.gif) repeat-x 0 -8px;
}
.toolkit .panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 0;
}
.toolkit .panel-body {
  padding-top: 0;
}
.toolkit .panel-title {
  font-size: 18px;
}
.toolkit .panel-title a {
  font-weight: 400;
  color: #002c6c;
}
.toolkit .dropdown-toggle {
  height: 34px;
  padding-left: 10px;
  padding-right: 8px;
  white-space: normal;
}
.toolkit .badge {
  float: right;
  background-color: transparent;
  color: #454545;
}
.toolkit .dropdown-toggle .badge {
  float: none;
  color: #f26334;
  background-color: #fff;
  margin-left: 15px;
  padding: 3px 5px;
}
.toolkit .structure-navigation-other a {
  font-weight: 300;
}
.toolkit .panel {
  border: none;
}
.toolkit .panel-default > .panel-heading {
  background: none;
  padding-top: 0;
}
.toolkit .panel-body form div {
  border-bottom: 1px solid #b1b3b3;
  color: #008dbd;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 15px;
}
.toolkit .panel-body form div:last-child {
  border-bottom: 0;
}
.toolkit .panel-body div.input[type="checkbox"]:checked {
  color: yellow;
}
.toolkit .list-group {
  border-radius: 0;
  box-shadow: none;
}
.toolkit .list-group-item {
  padding: 5px 0;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
.toolkit .item-l1,
.toolkit .item-l2,
.toolkit .item-l3 {
  border-bottom: 1px solid #b1b3b3;
  padding-right: 10px;
}
.toolkit .item-l2:last-of-type,
.toolkit .item-l3:last-of-type {
  border-bottom: 0;
}
.toolkit .item-l2-active > a,
.toolkit .item-l3-active > a {
  color: #002c6c;
  font-weight: 400;
}
.toolkit .item-l1 > a {
  font-size: 22px;
  color: #002c6c;
}
.toolkit .item-l1 > a:hover {
  color: #f26334;
}
.toolkit .local-navigation {
  margin-top: 20px;
}
.toolkit .local-navigation .group-l2,
.toolkit .local-navigation .group-l3 {
  margin-bottom: 0;
}
.toolkit .list-group.group-l3 {
  margin-left: 15px;
}
.toolkit .sidebar-list h4 {
  font-weight: 400;
}
.toolkit .sidebar-list .list-group {
  margin-left: 15px;
  padding-right: 10px;
}
.toolkit .sidebar-list .list-group-item {
  border-bottom: 1px solid #b1b3b3;
}
.toolkit .sidebar-list .list-group-item:last-of-type {
  border-bottom: 0;
}
.toolkit .nav-tags {
  margin-top: 5px;
  margin-bottom: 15px;
}
.toolkit .nav-tags .navtag {
  border: 1px solid #b1b3b3;
  padding: 5px;
  margin-right: 10px;
  font-weight: 300;
}
.toolkit .nav-tags .navtag:last-of-type {
  margin-right: 0;
}
.toolkit .nav-tags .navtag-strong a {
  font-weight: 500;
}
.toolkit .breadcrumb {
  margin-top: 20px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
}
.toolkit .breadcrumb > li + li:before {
  content: ">\00a0";
  padding: 0 5px;
  color: #b1b3b3;
}
.toolkit .breadcrumb > .active {
  color: #002c6c;
  font-weight: 500;
}
.toolkit .pagination li a {
  background-color: #f4f4f4;
}
.toolkit .pagination .active a {
  background-color: #002c6c;
  pointer-events: none;
}
.toolkit .pagination > li:last-child > a,
.toolkit .pagination > li:last-child > span,
.toolkit .pagination > li:first-child > a,
.toolkit .pagination > li:first-child > span {
  border-radius: 0;
}
.toolkit .pagination > li > a:hover,
.toolkit .pagination > li > span:hover,
.toolkit .pagination > li > a:focus,
.toolkit .pagination > li > span:focus {
  color: #002c6c;
}
.toolkit .pagination [class^="icon-"],
.toolkit .pagination [class*=" icon-"] {
  color: #f26334;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: bold;
}
.toolkit .nav-btn-group {
  margin-bottom: 20px;
}
.toolkit .nav-btn-group .btn-default {
  padding: 20px;
  color: #008dbd;
  border: 1px solid #b1b3b3;
}
@media only screen and (max-width: 992px) {
  .toolkit .nav-btn-group .btn-default {
    font-size: 12px;
    padding: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .toolkit .nav-btn-group .btn-default {
    font-size: 12px;
    padding: 10px;
  }
}
.toolkit .nav-btn-group .btn-default:hover {
  background-color: #fff;
  color: #002c6c;
  border: 1px solid #b1b3b3;
}
.toolkit .nav-btn-group .btn-default.selected {
  background-color: #002c6c;
  color: #fff;
  pointer-events: none;
}
.toolkit .ancillary-nav1 {
  position: absolute;
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav1 {
    position: relative;
  }
}
.toolkit .ancillary-nav2 {
  position: relative;
  left: -215px;
  top: -80px;
  width: 450px;
  font-size: .8em;
}
@media only screen and (min-width: 1200px) {
  .toolkit .ancillary-nav2 {
    left: -168px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 {
    left: 437px;
    top: -155px;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 {
    left: 0;
    top: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 992px) {
  .toolkit .ancillary-nav2 .input-wrapper {
    width: 20%;
  }
}
.toolkit .ancillary-nav2 .form-group {
  margin-bottom: 0;
  margin-left: 15px;
  margin-top: 10px;
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 .btn-primary {
    clear: both;
    float: left;
    margin-left: 15px;
    margin-top: 20px;
  }
}
.toolkit .ancillary-nav2 p {
  text-align: right;
  position: relative;
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 p {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 p {
    left: -167px;
    font-size: 12px;
  }
}
.toolkit .ancillary-nav2 .glyphicon-user {
  padding-right: 5px;
}
@media only screen and (max-width: 992px) {
  .toolkit .ancillary-nav2 .glyphicon-user {
    display: none;
  }
}
.toolkit .modal {
  z-index: 10011;
}
@media only screen and (min-width: 768px) {
  .toolkit .modal-header,
  .toolkit .modal-body,
  .toolkit .modal-footer {
    padding-left: 75px;
    padding-right: 75px;
  }
}
.toolkit .modal-content {
  border-radius: 0;
}
.toolkit .modal-header {
  border: 0;
  border-top: 7px solid #22bcb9;
}
@media only screen and (min-width: 768px) {
  .toolkit .modal-header {
    padding-top: 45px;
  }
}
.toolkit .modal-footer {
  border: 0;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .toolkit .modal-footer {
    padding-bottom: 50px;
  }
}
.toolkit .progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}
.toolkit .progress-bar {
  background-image: -webkit-linear-gradient(top, #428bca 0%, #3071a9 100%);
  background-image: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff428bca', endColorstr='#ff3071a9', GradientType=0);
}
.toolkit .progress-bar-success {
  background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}
.toolkit .progress-bar-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}
.toolkit .progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
}
.toolkit .progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}
.toolkit .list-group,
.toolkit .list-group-item.active,
.toolkit .list-group-item.active:hover,
.toolkit .list-group-item.active:focus {
  border-radius: 0;
}
.toolkit table tbody tr:last-child td {
  border-bottom: 1px solid #b1b3b3;
}
.toolkit table tbody tr:nth-child(odd) td {
  border-bottom: 0;
}
.toolkit .usage {
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 10px;
  background-color: #e5f6fa;
}
.toolkit .usage > h5 {
  font-weight: 400;
  padding-bottom: 5px;
}
.toolkit .highlight {
  margin: 10px 0 40px 0;
  clear: both;
  float: none;
  padding: 10px 20px 20px;
  background-color: #f4f4f4;
}
.toolkit .highlight h5 {
  font-weight: 400;
  padding: 0;
  margin: 20px 0 0px 0;
}
.toolkit .highlight pre {
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
}
.toolkit .highlight code {
  font-size: 15px;
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New", monospace;
  font-style: normal;
  font-weight: 500;
  color: #7ac143;
}
.toolkit .highlight p {
  margin-top: 20px;
  font-weight: 300;
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
  font-size: 14px;
}
.toolkit .highlight {
  display: none;
}
@media only screen and (min-width: 992px) {
  .toolkit .highlight {
    display: block;
  }
}
.toolkit .btn-secondary.collapsed .collapse-button-hide {
  display: none;
}
.toolkit .btn-secondary.collapsed .collapse-button-show {
  display: block;
}
.toolkit .btn-secondary .collapse-button-show {
  display: none;
}
.toolkit .collapse.in {
  display: block;
}
.toolkit .bg-transparent {
  background-color: transparent !important;
}
.toolkit .color-transparent {
  color: transparent !important;
}
.toolkit .color-transparent-hover:hover,
.toolkit .color-transparent-hover:active,
.toolkit .color-transparent-hover:focus {
  color: transparent !important;
}
.toolkit .top-border-transparent {
  border-top-color: transparent !important;
}
.toolkit .bg-transparent-active,
.toolkit .btn-primary.bg-transparent:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.toolkit .bg-transparent-disabled,
.toolkit .bg-transparent-disabled:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.toolkit .bg-blue {
  background-color: #002c6c !important;
}
.toolkit .color-blue {
  color: #002c6c !important;
}
.toolkit .color-blue-hover:hover,
.toolkit .color-blue-hover:active,
.toolkit .color-blue-hover:focus {
  color: #002c6c !important;
}
.toolkit .top-border-blue {
  border-top-color: #002c6c !important;
}
.toolkit .bg-blue-active,
.toolkit .btn-primary.bg-blue:hover {
  background-color: #002c6c !important;
}
.toolkit .bg-blue-disabled,
.toolkit .bg-blue-disabled:hover {
  background-color: #0056d2 !important;
}
.toolkit .bg-dark-gray {
  background-color: #454545 !important;
}
.toolkit .color-dark-gray {
  color: #454545 !important;
}
.toolkit .color-dark-gray-hover:hover,
.toolkit .color-dark-gray-hover:active,
.toolkit .color-dark-gray-hover:focus {
  color: #454545 !important;
}
.toolkit .top-border-dark-gray {
  border-top-color: #454545 !important;
}
.toolkit .bg-dark-gray-active,
.toolkit .btn-primary.bg-dark-gray:hover {
  background-color: #1f1f1f !important;
}
.toolkit .bg-dark-gray-disabled,
.toolkit .bg-dark-gray-disabled:hover {
  background-color: #9e9e9e !important;
}
.toolkit .bg-link {
  background-color: #008dbd !important;
}
.toolkit .color-link {
  color: #008dbd !important;
}
.toolkit .color-link-hover:hover,
.toolkit .color-link-hover:active,
.toolkit .color-link-hover:focus {
  color: #008dbd !important;
}
.toolkit .top-border-link {
  border-top-color: #008dbd !important;
}
.toolkit .bg-link-active,
.toolkit .btn-primary.bg-link:hover {
  background-color: #005471 !important;
}
.toolkit .bg-link-disabled,
.toolkit .bg-link-disabled:hover {
  background-color: #71dbff !important;
}
.toolkit .bg-light-medium-gray {
  background-color: #b1b3b3 !important;
}
.toolkit .color-light-medium-gray {
  color: #b1b3b3 !important;
}
.toolkit .color-light-medium-gray-hover:hover,
.toolkit .color-light-medium-gray-hover:active,
.toolkit .color-light-medium-gray-hover:focus {
  color: #b1b3b3 !important;
}
.toolkit .top-border-light-medium-gray {
  border-top-color: #b1b3b3 !important;
}
.toolkit .bg-light-medium-gray-active,
.toolkit .btn-primary.bg-light-medium-gray:hover {
  background-color: #8a8d8d !important;
}
.toolkit .bg-light-medium-gray-disabled,
.toolkit .bg-light-medium-gray-disabled:hover {
  background-color: #e5e5e5 !important;
}
.toolkit .bg-dark-medium-gray {
  background-color: #888b8d !important;
}
.toolkit .color-dark-medium-gray {
  color: #888b8d !important;
}
.toolkit .color-dark-medium-gray-hover:hover,
.toolkit .color-dark-medium-gray-hover:active,
.toolkit .color-dark-medium-gray-hover:focus {
  color: #888b8d !important;
}
.toolkit .top-border-dark-medium-gray {
  border-top-color: #888b8d !important;
}
.toolkit .bg-dark-medium-gray-active,
.toolkit .btn-primary.bg-dark-medium-gray:hover {
  background-color: #626566 !important;
}
.toolkit .bg-dark-medium-gray-disabled,
.toolkit .bg-dark-medium-gray-disabled:hover {
  background-color: #bcbebf !important;
}
.toolkit .bg-white {
  background-color: #ffffff !important;
}
.toolkit .color-white {
  color: #ffffff !important;
}
.toolkit .color-white-hover:hover,
.toolkit .color-white-hover:active,
.toolkit .color-white-hover:focus {
  color: #ffffff !important;
}
.toolkit .top-border-white {
  border-top-color: #ffffff !important;
}
.toolkit .bg-white-active,
.toolkit .btn-primary.bg-white:hover {
  background-color: #d9d9d9 !important;
}
.toolkit .bg-white-disabled,
.toolkit .bg-white-disabled:hover {
  background-color: #ffffff !important;
}
.toolkit .bg-light-gray {
  background-color: #f4f4f4 !important;
}
.toolkit .color-light-gray {
  color: #f4f4f4 !important;
}
.toolkit .color-light-gray-hover:hover,
.toolkit .color-light-gray-hover:active,
.toolkit .color-light-gray-hover:focus {
  color: #f4f4f4 !important;
}
.toolkit .top-border-light-gray {
  border-top-color: #f4f4f4 !important;
}
.toolkit .bg-light-gray-active,
.toolkit .btn-primary.bg-light-gray:hover {
  background-color: #cecece !important;
}
.toolkit .bg-light-gray-disabled,
.toolkit .bg-light-gray-disabled:hover {
  background-color: #ffffff !important;
}
.toolkit .bg-orange {
  background-color: #f26334 !important;
}
.toolkit .color-orange {
  color: #f26334 !important;
}
.toolkit .color-orange-hover:hover,
.toolkit .color-orange-hover:active,
.toolkit .color-orange-hover:focus {
  color: #f26334 !important;
}
.toolkit .top-border-orange {
  border-top-color: #f26334 !important;
}
.toolkit .bg-orange-active,
.toolkit .btn-primary.bg-orange:hover {
  background-color: #cc3c0d !important;
}
.toolkit .bg-orange-disabled,
.toolkit .bg-orange-disabled:hover {
  background-color: #f8ad94 !important;
}
.toolkit .bg-raspberry {
  background-color: #f05587 !important;
}
.toolkit .color-raspberry {
  color: #f05587 !important;
}
.toolkit .color-raspberry-hover:hover,
.toolkit .color-raspberry-hover:active,
.toolkit .color-raspberry-hover:focus {
  color: #f05587 !important;
}
.toolkit .top-border-raspberry {
  border-top-color: #f05587 !important;
}
.toolkit .bg-raspberry-active,
.toolkit .btn-primary.bg-raspberry:hover {
  background-color: #e41457 !important;
}
.toolkit .bg-raspberry-disabled,
.toolkit .bg-raspberry-disabled:hover {
  background-color: #f8b3c9 !important;
}
.toolkit .bg-purple {
  background-color: #bf83b9 !important;
}
.toolkit .color-purple {
  color: #bf83b9 !important;
}
.toolkit .color-purple-hover:hover,
.toolkit .color-purple-hover:active,
.toolkit .color-purple-hover:focus {
  color: #bf83b9 !important;
}
.toolkit .top-border-purple {
  border-top-color: #bf83b9 !important;
}
.toolkit .bg-purple-active,
.toolkit .btn-primary.bg-purple:hover {
  background-color: #a2549a !important;
}
.toolkit .bg-purple-disabled,
.toolkit .bg-purple-disabled:hover {
  background-color: #e2c6df !important;
}
.toolkit .bg-tangerine {
  background-color: #ff8200 !important;
}
.toolkit .color-tangerine {
  color: #ff8200 !important;
}
.toolkit .color-tangerine-hover:hover,
.toolkit .color-tangerine-hover:active,
.toolkit .color-tangerine-hover:focus {
  color: #ff8200 !important;
}
.toolkit .top-border-tangerine {
  border-top-color: #ff8200 !important;
}
.toolkit .bg-tangerine-active,
.toolkit .btn-primary.bg-tangerine:hover {
  background-color: #b35b00 !important;
}
.toolkit .bg-tangerine-disabled,
.toolkit .bg-tangerine-disabled:hover {
  background-color: #ffb466 !important;
}
.toolkit .bg-peach {
  background-color: #fbb034 !important;
}
.toolkit .color-peach {
  color: #fbb034 !important;
}
.toolkit .color-peach-hover:hover,
.toolkit .color-peach-hover:active,
.toolkit .color-peach-hover:focus {
  color: #fbb034 !important;
}
.toolkit .top-border-peach {
  border-top-color: #fbb034 !important;
}
.toolkit .bg-peach-active,
.toolkit .btn-primary.bg-peach:hover {
  background-color: #de8c04 !important;
}
.toolkit .bg-peach-disabled,
.toolkit .bg-peach-disabled:hover {
  background-color: #fdd798 !important;
}
.toolkit .bg-sky {
  background-color: #00b6de !important;
}
.toolkit .color-sky {
  color: #00b6de !important;
}
.toolkit .color-sky-hover:hover,
.toolkit .color-sky-hover:active,
.toolkit .color-sky-hover:focus {
  color: #00b6de !important;
}
.toolkit .top-border-sky {
  border-top-color: #00b6de !important;
}
.toolkit .bg-sky-active,
.toolkit .btn-primary.bg-sky:hover {
  background-color: #007792 !important;
}
.toolkit .bg-sky-disabled,
.toolkit .bg-sky-disabled:hover {
  background-color: #78e7ff !important;
}
.toolkit .bg-teal {
  background-color: #22bcb9 !important;
}
.toolkit .color-teal {
  color: #22bcb9 !important;
}
.toolkit .color-teal-hover:hover,
.toolkit .color-teal-hover:active,
.toolkit .color-teal-hover:focus {
  color: #22bcb9 !important;
}
.toolkit .top-border-teal {
  border-top-color: #22bcb9 !important;
}
.toolkit .bg-teal-active,
.toolkit .btn-primary.bg-teal:hover {
  background-color: #167b79 !important;
}
.toolkit .bg-teal-disabled,
.toolkit .bg-teal-disabled:hover {
  background-color: #61e3e0 !important;
}
.toolkit .bg-grass {
  background-color: #7ac143 !important;
}
.toolkit .color-grass {
  color: #7ac143 !important;
}
.toolkit .color-grass-hover:hover,
.toolkit .color-grass-hover:active,
.toolkit .color-grass-hover:focus {
  color: #7ac143 !important;
}
.toolkit .top-border-grass {
  border-top-color: #7ac143 !important;
}
.toolkit .bg-grass-active,
.toolkit .btn-primary.bg-grass:hover {
  background-color: #568a2e !important;
}
.toolkit .bg-grass-disabled,
.toolkit .bg-grass-disabled:hover {
  background-color: #b0da90 !important;
}
.toolkit .bg-gold {
  background-color: #c4b000 !important;
}
.toolkit .color-gold {
  color: #c4b000 !important;
}
.toolkit .color-gold-hover:hover,
.toolkit .color-gold-hover:active,
.toolkit .color-gold-hover:focus {
  color: #c4b000 !important;
}
.toolkit .top-border-gold {
  border-top-color: #c4b000 !important;
}
.toolkit .bg-gold-active,
.toolkit .btn-primary.bg-gold:hover {
  background-color: #786b00 !important;
}
.toolkit .bg-gold-disabled,
.toolkit .bg-gold-disabled:hover {
  background-color: #ffe92b !important;
}
.toolkit .bg-mint {
  background-color: #71b790 !important;
}
.toolkit .color-mint {
  color: #71b790 !important;
}
.toolkit .color-mint-hover:hover,
.toolkit .color-mint-hover:active,
.toolkit .color-mint-hover:focus {
  color: #71b790 !important;
}
.toolkit .top-border-mint {
  border-top-color: #71b790 !important;
}
.toolkit .bg-mint-active,
.toolkit .btn-primary.bg-mint:hover {
  background-color: #4a926a !important;
}
.toolkit .bg-mint-disabled,
.toolkit .bg-mint-disabled:hover {
  background-color: #b5d9c5 !important;
}
.toolkit .bg-lime {
  background-color: #c1d82f !important;
}
.toolkit .color-lime {
  color: #c1d82f !important;
}
.toolkit .color-lime-hover:hover,
.toolkit .color-lime-hover:active,
.toolkit .color-lime-hover:focus {
  color: #c1d82f !important;
}
.toolkit .top-border-lime {
  border-top-color: #c1d82f !important;
}
.toolkit .bg-lime-active,
.toolkit .btn-primary.bg-lime:hover {
  background-color: #8c9d1d !important;
}
.toolkit .bg-lime-disabled,
.toolkit .bg-lime-disabled:hover {
  background-color: #e1ec9a !important;
}
.toolkit .bg-lavender {
  background-color: #af96d4 !important;
}
.toolkit .color-lavender {
  color: #af96d4 !important;
}
.toolkit .color-lavender-hover:hover,
.toolkit .color-lavender-hover:active,
.toolkit .color-lavender-hover:focus {
  color: #af96d4 !important;
}
.toolkit .top-border-lavender {
  border-top-color: #af96d4 !important;
}
.toolkit .bg-lavender-active,
.toolkit .btn-primary.bg-lavender:hover {
  background-color: #8660be !important;
}
.toolkit .bg-lavender-disabled,
.toolkit .bg-lavender-disabled:hover {
  background-color: #e6def2 !important;
}
.toolkit .bg-terracotta {
  background-color: #d3875f !important;
}
.toolkit .color-terracotta {
  color: #d3875f !important;
}
.toolkit .color-terracotta-hover:hover,
.toolkit .color-terracotta-hover:active,
.toolkit .color-terracotta-hover:focus {
  color: #d3875f !important;
}
.toolkit .top-border-terracotta {
  border-top-color: #d3875f !important;
}
.toolkit .bg-terracotta-active,
.toolkit .btn-primary.bg-terracotta:hover {
  background-color: #b45f32 !important;
}
.toolkit .bg-terracotta-disabled,
.toolkit .bg-terracotta-disabled:hover {
  background-color: #e9c3af !important;
}
.toolkit .bg-olive {
  background-color: #9dbb68 !important;
}
.toolkit .color-olive {
  color: #9dbb68 !important;
}
.toolkit .color-olive-hover:hover,
.toolkit .color-olive-hover:active,
.toolkit .color-olive-hover:focus {
  color: #9dbb68 !important;
}
.toolkit .top-border-olive {
  border-top-color: #9dbb68 !important;
}
.toolkit .bg-olive-active,
.toolkit .btn-primary.bg-olive:hover {
  background-color: #779443 !important;
}
.toolkit .bg-olive-disabled,
.toolkit .bg-olive-disabled:hover {
  background-color: #cbdbae !important;
}
.toolkit .bg-mist {
  background-color: #8db9ca !important;
}
.toolkit .color-mist {
  color: #8db9ca !important;
}
.toolkit .color-mist-hover:hover,
.toolkit .color-mist-hover:active,
.toolkit .color-mist-hover:focus {
  color: #8db9ca !important;
}
.toolkit .top-border-mist {
  border-top-color: #8db9ca !important;
}
.toolkit .bg-mist-active,
.toolkit .btn-primary.bg-mist:hover {
  background-color: #5999b2 !important;
}
.toolkit .bg-mist-disabled,
.toolkit .bg-mist-disabled:hover {
  background-color: #d3e4ea !important;
}
.toolkit .bg-honey {
  background-color: #b78b20 !important;
}
.toolkit .color-honey {
  color: #b78b20 !important;
}
.toolkit .color-honey-hover:hover,
.toolkit .color-honey-hover:active,
.toolkit .color-honey-hover:focus {
  color: #b78b20 !important;
}
.toolkit .top-border-honey {
  border-top-color: #b78b20 !important;
}
.toolkit .bg-honey-active,
.toolkit .btn-primary.bg-honey:hover {
  background-color: #765a15 !important;
}
.toolkit .bg-honey-disabled,
.toolkit .bg-honey-disabled:hover {
  background-color: #e2bb5b !important;
}
.toolkit .bg-forest {
  background-color: #00b74f !important;
}
.toolkit .color-forest {
  color: #00b74f !important;
}
.toolkit .color-forest-hover:hover,
.toolkit .color-forest-hover:active,
.toolkit .color-forest-hover:focus {
  color: #00b74f !important;
}
.toolkit .top-border-forest {
  border-top-color: #00b74f !important;
}
.toolkit .bg-forest-active,
.toolkit .btn-primary.bg-forest:hover {
  background-color: #006b2e !important;
}
.toolkit .bg-forest-disabled,
.toolkit .bg-forest-disabled:hover {
  background-color: #6affab !important;
}
.toolkit .bg-slate {
  background-color: #89aadb !important;
}
.toolkit .color-slate {
  color: #89aadb !important;
}
.toolkit .color-slate-hover:hover,
.toolkit .color-slate-hover:active,
.toolkit .color-slate-hover:focus {
  color: #89aadb !important;
}
.toolkit .top-border-slate {
  border-top-color: #89aadb !important;
}
.toolkit .bg-slate-active,
.toolkit .btn-primary.bg-slate:hover {
  background-color: #4e80c9 !important;
}
.toolkit .bg-slate-disabled,
.toolkit .bg-slate-disabled:hover {
  background-color: #d7e2f3 !important;
}
.toolkit .no-padding {
  padding-left: 0;
  padding-right: 0;
}
.toolkit .btn-disable {
  pointer-events: none;
}
.toolkit .glyphicon-disabled {
  opacity: 0.3;
}
.toolkit hr {
  background-color: #b1b3b3;
  border: 0 none;
  clear: both;
  color: #f4f4f4;
  height: 1px;
  margin: 25px 0;
}
@font-face {
  font-family: 'gs1_icons';
  src: url('../fonts/gs1_icons.eot?-xldby5');
  src: url('../fonts/gs1_icons.eot?#iefix-xldby5') format('embedded-opentype'), url('../fonts/gs1_icons.ttf?-xldby5') format('truetype'), url('../fonts/gs1_icons.woff?-xldby5') format('woff'), url('../fonts/gs1_icons.svg?-xldby5#gs1_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
.toolkit [class^="icon-"]:before,
.toolkit [class*=" icon-"]:before,
.toolkit .downloads li:before {
  font-family: 'gs1_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.toolkit .icon-arrow_down_2:before {
  content: "\e600";
}
.toolkit .icon-arrow_down:before {
  content: "\e601";
}
.toolkit .icon-arrow_left_2:before {
  content: "\e602";
}
.toolkit .icon-arrow_left:before {
  content: "\e603";
}
.toolkit .icon-arrow_right_2:before {
  content: "\e604";
}
.toolkit .icon-arrow_right:before {
  content: "\e605";
}
.toolkit .icon-arrow_up_2:before {
  content: "\e606";
}
.toolkit .icon-arrow_up:before {
  content: "\e607";
}
.toolkit .icon-backward:before {
  content: "\e608";
}
.toolkit .icon-ban_circle:before {
  content: "\e609";
}
.toolkit .icon-barcode:before {
  content: "\e60a";
}
.toolkit .icon-book:before {
  content: "\e60b";
}
.toolkit .icon-bookmark:before {
  content: "\e60c";
}
.toolkit .icon-calculator:before {
  content: "\e60d";
}
.toolkit .icon-calendar:before {
  content: "\e60e";
}
.toolkit .icon-check_file:before {
  content: "\e60f";
}
.toolkit .icon-check:before {
  content: "\e610";
}
.toolkit .icon-circle_arrow_down:before {
  content: "\e611";
}
.toolkit .icon-circle_arrow_left:before {
  content: "\e612";
}
.toolkit .icon-circle_arrow_right:before {
  content: "\e613";
}
.toolkit .icon-circle_arrow_up:before {
  content: "\e614";
}
.toolkit .icon-clock:before {
  content: "\e615";
}
.toolkit .icon-close:before {
  content: "\e616";
}
.toolkit .icon-cloud_download:before {
  content: "\e617";
}
.toolkit .icon-cloud_upload:before {
  content: "\e618";
}
.toolkit .icon-cog:before {
  content: "\e619";
}
.toolkit .icon-comment:before {
  content: "\e61a";
}
.toolkit .icon-credit_card:before {
  content: "\e61b";
}
.toolkit .icon-dashboard:before {
  content: "\e61c";
}
.toolkit .icon-download:before {
  content: "\e61d";
}
.toolkit .icon-earphone:before {
  content: "\e61e";
}
.toolkit .icon-envelope:before {
  content: "\e61f";
}
.toolkit .icon-excel_file:before {
  content: "\e620";
}
.toolkit .icon-exclamation_sign:before {
  content: "\e621";
}
.toolkit .icon-expand:before {
  content: "\e622";
}
.toolkit .icon-facebook:before {
  content: "\e623";
}
.toolkit .icon-fast_backward:before {
  content: "\e624";
}
.toolkit .icon-fast_forward:before {
  content: "\e625";
}
.toolkit .icon-file:before {
  content: "\e626";
}
.toolkit .icon-flag:before {
  content: "\e627";
}
.toolkit .icon-flickr:before {
  content: "\e628";
}
.toolkit .icon-folder_close:before {
  content: "\e629";
}
.toolkit .icon-folder_open:before {
  content: "\e62a";
}
.toolkit .icon-forward:before {
  content: "\e62b";
}
.toolkit .icon-globe:before {
  content: "\e62c";
}
.toolkit .icon-google_plus:before {
  content: "\e62d";
}
.toolkit .icon-heart:before {
  content: "\e62e";
}
.toolkit .icon-help:before {
  content: "\e62f";
}
.toolkit .icon-home:before {
  content: "\e630";
}
.toolkit .icon-inbox:before {
  content: "\e631";
}
.toolkit .icon-info_sign:before {
  content: "\e632";
}
.toolkit .icon-instagram:before {
  content: "\e633";
}
.toolkit .icon-issuu:before {
  content: "\e634";
}
.toolkit .icon-linkedin:before {
  content: "\e635";
}
.toolkit .icon-list:before {
  content: "\e636";
}
.toolkit .icon-location:before {
  content: "\e637";
}
.toolkit .icon-lock:before {
  content: "\e638";
}
.toolkit .icon-menu:before {
  content: "\e639";
}
.toolkit .icon-minus:before {
  content: "\e63a";
}
.toolkit .icon-move:before {
  content: "\e63b";
}
.toolkit .icon-music_file:before {
  content: "\e63c";
}
.toolkit .icon-not_complete:before {
  content: "\e63d";
}
.toolkit .icon-ok_circle:before {
  content: "\e63e";
}
.toolkit .icon-ok_sign:before {
  content: "\e63f";
}
.toolkit .icon-open_in_new_tab:before {
  content: "\e640";
}
.toolkit .icon-open_lock:before {
  content: "\e641";
}
.toolkit .icon-paperclip:before {
  content: "\e642";
}
.toolkit .icon-pause:before {
  content: "\e643";
}
.toolkit .icon-pdf_file:before {
  content: "\e644";
}
.toolkit .icon-pencil:before {
  content: "\e645";
}
.toolkit .icon-play:before {
  content: "\e646";
}
.toolkit .icon-plus:before {
  content: "\e647";
}
.toolkit .icon-ppt_file:before {
  content: "\e648";
}
.toolkit .icon-print:before {
  content: "\e649";
}
.toolkit .icon-refresh:before {
  content: "\e64a";
}
.toolkit .icon-remove_circle:before {
  content: "\e64b";
}
.toolkit .icon-remove_file:before {
  content: "\e64c";
}
.toolkit .icon-remove_sign:before {
  content: "\e64d";
}
.toolkit .icon-search:before {
  content: "\e64e";
}
.toolkit .icon-share_alt:before {
  content: "\e64f";
}
.toolkit .icon-shopping_cart:before {
  content: "\e650";
}
.toolkit .icon-skip_next:before {
  content: "\e651";
}
.toolkit .icon-skip_start:before {
  content: "\e652";
}
.toolkit .icon-smartphone:before {
  content: "\e653";
}
.toolkit .icon-sort:before {
  content: "\e654";
}
.toolkit .icon-star_empty:before {
  content: "\e655";
}
.toolkit .icon-star:before {
  content: "\e656";
}
.toolkit .icon-stats:before {
  content: "\e657";
}
.toolkit .icon-stop:before {
  content: "\e658";
}
.toolkit .icon-thumbs_down:before {
  content: "\e659";
}
.toolkit .icon-thumbs_up:before {
  content: "\e65a";
}
.toolkit .icon-transfer:before {
  content: "\e65b";
}
.toolkit .icon-trashcan:before {
  content: "\e65c";
}
.toolkit .icon-twitter:before {
  content: "\e65d";
}
.toolkit .icon-upload:before {
  content: "\e65e";
}
.toolkit .icon-user:before {
  content: "\e65f";
}
.toolkit .icon-video_file:before {
  content: "\e660";
}
.toolkit .icon-volume_down:before {
  content: "\e661";
}
.toolkit .icon-volume_off:before {
  content: "\e662";
}
.toolkit .icon-volume_up:before {
  content: "\e663";
}
.toolkit .icon-warning_file:before {
  content: "\e664";
}
.toolkit .icon-warning_sign:before {
  content: "\e665";
}
.toolkit .icon-word_file:before {
  content: "\e666";
}
.toolkit .icon-youtube:before {
  content: "\e667";
}
.toolkit .icon-zoom_in:before {
  content: "\e668";
}
.toolkit .icon-zoom_out:before {
  content: "\e669";
}
.toolkit .jumbotron {
  padding: 0;
}
.toolkit .jumbotron .container,
.toolkit .jumbotron img {
  width: 100%;
  padding: 0;
}
.toolkit .jumbotron-spacing {
  margin: 40px 0;
  padding: 40px 0;
}
.toolkit .jumbotron-background {
  background-position: 50% 50%;
  background-size: cover;
}
.toolkit footer {
  margin: 20px 0;
}
.toolkit footer h4 {
  margin-top: 0;
}
.toolkit footer p {
  display: block;
  margin: 0;
  font-size: 12px;
}
.toolkit footer a {
  font-weight: 300;
}
.toolkit footer ul {
  list-style-type: none;
  margin-bottom: 20px;
  padding: 0;
}
.toolkit footer ul li {
  line-height: 1em;
  margin-bottom: 7px;
}
.toolkit footer ul li > a {
  font-size: 0.875em;
}
.toolkit footer .icon {
  font-size: 40px;
}
.toolkit footer .footer-links {
  font-size: 0.875em;
  margin-top: 20px;
  margin-bottom: 20px;
}
.toolkit footer .footer-links hr {
  margin-bottom: 15px;
  margin-top: 0;
}
.toolkit footer .footer-links .link-pipe {
  color: #b1b3b3;
  margin: 0 15px;
}
.toolkit footer .social a {
  color: #002c6c;
}
.toolkit footer .social a:hover {
  color: #008dbd;
}
.toolkit .navbar-fixed-top + .content-container {
  margin-top: 70px;
}
.toolkit .content-container {
  margin: 0 130px;
}
.toolkit #top-link-block {
  z-index: 5000;
}
.toolkit #top-link-block .affix-top {
  position: absolute;
  /* allows it to "slide" up into view */
  bottom: -82px;
  /* negative of the offset - height of link element */
  left: 10px;
  /* padding from the left side of the window */
}
.toolkit #top-link-block .affix {
  position: fixed;
  /* keeps it on the bottom once in view */
  bottom: 18px;
  /* height of link element */
  left: 10px;
  /* padding from the left side of the window */
}
.toolkit .vertical-center-outer {
  display: table;
  overflow: hidden;
}
.toolkit .vertical-center-inner {
  display: table-cell;
  vertical-align: middle;
}
.toolkit a {
  font-size: 14px\9;
}
.toolkit .pointer2 {
  display: none\9;
}
.toolkit.header-short header .logo1 {
  padding-bottom: 15px\9;
  padding-top: 15px\9;
}
.toolkit.documentation .logos-section,
.toolkit.brand-home .logos-section {
  border-bottom: 1px solid #b1b3b3;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.toolkit.documentation .logos-section:last-of-type,
.toolkit.brand-home .logos-section:last-of-type {
  border-bottom: none;
}
.toolkit.documentation .logo-image,
.toolkit.brand-home .logo-image {
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
}
.toolkit.documentation .logo-circle,
.toolkit.brand-home .logo-circle {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  vertical-align: top;
  float: left;
  position: relative;
  margin: 10px 10px 10px 0;
}
.toolkit.documentation .logo-circle .color-description,
.toolkit.brand-home .logo-circle .color-description {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.toolkit.documentation .logo-circle .title,
.toolkit.brand-home .logo-circle .title {
  color: #454545;
  font-size: .7em;
  left: 0;
  line-height: 1.3em;
  position: absolute;
  top: 160px;
  width: 150px;
}
.toolkit.documentation .logo-circle.bg-white,
.toolkit.brand-home .logo-circle.bg-white {
  border: 1px solid #454545;
  color: #454545;
}
.toolkit.documentation .logo-circle.bg-lightgray,
.toolkit.brand-home .logo-circle.bg-lightgray {
  color: #454545;
}
.toolkit.documentation .captions .logo-circle,
.toolkit.brand-home .captions .logo-circle {
  margin-bottom: 60px;
}
.toolkit.documentation header.example-header,
.toolkit.brand-home header.example-header {
  box-shadow: none;
  position: static;
}
.toolkit.documentation .yamm .btn-download,
.toolkit.brand-home .yamm .btn-download {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .toolkit.documentation .yamm .btn-download,
  .toolkit.brand-home .yamm .btn-download {
    padding-right: 16px;
  }
  .toolkit.documentation .yamm .btn-download .icon-download,
  .toolkit.brand-home .yamm .btn-download .icon-download {
    display: none;
  }
}
.toolkit.documentation .screenshot,
.toolkit.brand-home .screenshot {
  border: 1px solid #b1b3b3;
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .toolkit.documentation .actions,
  .toolkit.brand-home .actions {
    float: left;
  }
}
.toolkit.documentation .setting,
.toolkit.brand-home .setting {
  float: left;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  .toolkit.documentation .setting,
  .toolkit.brand-home .setting {
    float: right;
    margin-top: 0;
  }
}
.toolkit.documentation .setting label,
.toolkit.brand-home .setting label {
  font-size: 12px;
  font-weight: normal;
}
.toolkit.documentation figure,
.toolkit.brand-home figure {
  margin-top: 20px;
}
.toolkit.documentation img.preview,
.toolkit.brand-home img.preview {
  border: 1px solid #b1b3b3;
}
.toolkit.documentation .bg-light-gray img.preview,
.toolkit.brand-home .bg-light-gray img.preview {
  border-color: #888b8d;
}
.toolkit.documentation .example-button-pointer,
.toolkit.brand-home .example-button-pointer {
  display: block;
  position: relative;
  top: -15px;
  left: 20px;
}
@media only screen and (min-width: 992px) {
  .toolkit.documentation .logo2 {
    background-color: #008dbd;
  }
}
.toolkit.documentation table.status {
  width: 100%;
}
.toolkit.documentation table.status > tbody > tr:first-child > td {
  border-top: none;
}
.toolkit.documentation table.status tr td {
  text-align: right;
}
.toolkit.documentation table.status tr td:first-child {
  text-align: left;
}
.toolkit.documentation .section-spacing:last-of-type {
  border-bottom: none;
}
.toolkit.documentation .video {
  margin: 70px auto;
  width: 780px;
}
.toolkit.documentation .video p {
  margin-top: 35px;
}
.toolkit.brand-home .intro.site-content {
  padding-top: 0;
}
.toolkit.brand-home .intro .container {
  background: url(../images/world-background.png) no-repeat 10% 14%;
  height: 200px;
}
.toolkit.brand-home .intro h1 {
  font-size: 3.4em;
  font-weight: 200;
}
.toolkit.brand-home .intro p {
  font-size: 1.5em;
  line-height: 120%;
  letter-spacing: 1px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 768px) {
  .toolkit.brand-home .intro p {
    font-size: 3em;
  }
}
.toolkit.brand-home .section-spacing {
  border: none;
}
.modal-backdrop {
  z-index: 10010;
}
.flush-outside > .col-xs-1:first-child, .flush-outside > .col-sm-1:first-child, .flush-outside > .col-md-1:first-child, .flush-outside > .col-lg-1:first-child, .flush-outside > .col-xs-2:first-child, .flush-outside > .col-sm-2:first-child, .flush-outside > .col-md-2:first-child, .flush-outside > .col-lg-2:first-child, .flush-outside > .col-xs-3:first-child, .flush-outside > .col-sm-3:first-child, .flush-outside > .col-md-3:first-child, .flush-outside > .col-lg-3:first-child, .flush-outside > .col-xs-4:first-child, .flush-outside > .col-sm-4:first-child, .flush-outside > .col-md-4:first-child, .flush-outside > .col-lg-4:first-child, .flush-outside > .col-xs-5:first-child, .flush-outside > .col-sm-5:first-child, .flush-outside > .col-md-5:first-child, .flush-outside > .col-lg-5:first-child, .flush-outside > .col-xs-6:first-child, .flush-outside > .col-sm-6:first-child, .flush-outside > .col-md-6:first-child, .flush-outside > .col-lg-6:first-child, .flush-outside > .col-xs-7:first-child, .flush-outside > .col-sm-7:first-child, .flush-outside > .col-md-7:first-child, .flush-outside > .col-lg-7:first-child, .flush-outside > .col-xs-8:first-child, .flush-outside > .col-sm-8:first-child, .flush-outside > .col-md-8:first-child, .flush-outside > .col-lg-8:first-child, .flush-outside > .col-xs-9:first-child, .flush-outside > .col-sm-9:first-child, .flush-outside > .col-md-9:first-child, .flush-outside > .col-lg-9:first-child, .flush-outside > .col-xs-10:first-child, .flush-outside > .col-sm-10:first-child, .flush-outside > .col-md-10:first-child, .flush-outside > .col-lg-10:first-child, .flush-outside > .col-xs-11:first-child, .flush-outside > .col-sm-11:first-child, .flush-outside > .col-md-11:first-child, .flush-outside > .col-lg-11:first-child, .flush-outside > .col-xs-12:first-child, .flush-outside > .col-sm-12:first-child, .flush-outside > .col-md-12:first-child, .flush-outside > .col-lg-12:first-child {
  padding-left: 0;
}
.flush-outside > .col-xs-1:last-child, .flush-outside > .col-sm-1:last-child, .flush-outside > .col-md-1:last-child, .flush-outside > .col-lg-1:last-child, .flush-outside > .col-xs-2:last-child, .flush-outside > .col-sm-2:last-child, .flush-outside > .col-md-2:last-child, .flush-outside > .col-lg-2:last-child, .flush-outside > .col-xs-3:last-child, .flush-outside > .col-sm-3:last-child, .flush-outside > .col-md-3:last-child, .flush-outside > .col-lg-3:last-child, .flush-outside > .col-xs-4:last-child, .flush-outside > .col-sm-4:last-child, .flush-outside > .col-md-4:last-child, .flush-outside > .col-lg-4:last-child, .flush-outside > .col-xs-5:last-child, .flush-outside > .col-sm-5:last-child, .flush-outside > .col-md-5:last-child, .flush-outside > .col-lg-5:last-child, .flush-outside > .col-xs-6:last-child, .flush-outside > .col-sm-6:last-child, .flush-outside > .col-md-6:last-child, .flush-outside > .col-lg-6:last-child, .flush-outside > .col-xs-7:last-child, .flush-outside > .col-sm-7:last-child, .flush-outside > .col-md-7:last-child, .flush-outside > .col-lg-7:last-child, .flush-outside > .col-xs-8:last-child, .flush-outside > .col-sm-8:last-child, .flush-outside > .col-md-8:last-child, .flush-outside > .col-lg-8:last-child, .flush-outside > .col-xs-9:last-child, .flush-outside > .col-sm-9:last-child, .flush-outside > .col-md-9:last-child, .flush-outside > .col-lg-9:last-child, .flush-outside > .col-xs-10:last-child, .flush-outside > .col-sm-10:last-child, .flush-outside > .col-md-10:last-child, .flush-outside > .col-lg-10:last-child, .flush-outside > .col-xs-11:last-child, .flush-outside > .col-sm-11:last-child, .flush-outside > .col-md-11:last-child, .flush-outside > .col-lg-11:last-child, .flush-outside > .col-xs-12:last-child, .flush-outside > .col-sm-12:last-child, .flush-outside > .col-md-12:last-child, .flush-outside > .col-lg-12:last-child {
  padding-right: 0;
}
/* sourceMappingURL=bootstrap-theme.css.map */