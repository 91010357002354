.images-outer-container {
    font-family: sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
}
.images-container {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    transition: scroll 0.6s ease-in-out;
}

.slider-image {
    object-fit: contain;
    width: 150px;
    margin-right: 20px;
}

.nav-btn {
    color: blue;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: #FFFFFF;
    width: 30px;
    margin: 5px;
}