.arrow-down {
    margin-top:50px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #002C6C;
    transform: translateY(-50%);
}

.text1 {
    color:#d35400;
    font-weight:600;
    font-size:1.875em
}

.text1:hover {
    color: #002C6C;
}

.text2 {
    color:#002C6C;
    font-weight:400;
    font-size:1.275em
}

.text {
    color:white;
    font-weight:400;
    font-size:1.875em
}

.text:after {
    content: '';
    top: 100%;
    position: absolute;
    height: 0;
    border-style: solid;
    border-width: 1em;
    border-color: #002C6C transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
}

.item-wrapper .slick-arrow.slick-prev, .slick-slider .slick-arrow.slick-prev, .slick_slideshow .slick-arrow.slick-prev {
    right: 100px;
}

.slick_slideshow .slick-arrow {
    margin-right: auto;
}

.slick_slideshow .slick-arrow {
    width: 47px;
    height: 47px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 3px 0 #002c6c;
    z-index: 3;
    top: auto;
    bottom: 1.5rem;
    right: 30px;
    left: auto;
    margin-right: calc((100% - 1140px) / 2);
    transform: unset;
}
.slick-next::before {
    transform: translate(-50%, -50%) rotate(-45deg);
    left: calc(50% - 2px);
}
.slick-prev::before {
    font-family: none;
    border-style: solid;
    border-color: #002C6C;
    border-width: 0 1px 1px 0;
    display: block;
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    padding: 3px;
    font-size: 0px;
    opacity: 1;
}
.slick-arrow {
    width: 47px;
    height: 47px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 3px 0 #002c6c;
    z-index: 3;
    top: auto;
    bottom: 1.5rem;
    right: 30px;
    left: auto;
    margin-right: calc((100% - 1140px) / 2);
    transform: unset;
}