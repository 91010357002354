/**
 * Created by gantushig on 2/18/20.
 */

//#f26334
//#002c6c
table.tb {

    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid gray;
    color: #000000;

    thead {
        background-color: #f26334;
        color: #FFFFFF;
    }
    thead th:nth-child(3) {
        width: 30%;

    }
    tr {
        border: solid;
        border-width: 1px 0;
        border-color: silver;
    }
    th {


    }

    th, td {
        border-right: solid 1px silver;
        border-left: solid 1px silver;

        padding: 5px;
    }
}