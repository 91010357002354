/* Fixed/sticky icon bar (vertically aligned 50% from the top of the screen) */
.icon-bar-sticky-social {
    position: fixed;
    z-index:9999;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar-sticky-social a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
}

/* Style the social media icons with color, if you want */
.icon-bar-sticky-social a:hover {
    background-color: #000;
}

.facebook {
    background: #3B5998;
    color: white;
}

.twitter {
    background: #55ACEE;
    color: white;
}

.google {
    background: #dd4b39;
    color: white;
}

.linkedin {
    background: #007bb5;
    color: white;
}

.youtube {
    background: #bb0000;
    color: white;
}

@media (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }
}

.mydimensions {
    height: 25px;
    width: 25px;
}