//@font-face {
//  font-family: 'Gotham Pro';
//  font-style: normal;
//  font-weight: normal;
//  src: url('../../static/fonts/GothaProReg.woff') format('woff');
//}
//



@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham Pro'), url('../fonts/GothaProReg.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Gotham Pro'), url('../fonts/GothaProReg.woff') format('woff');
}


@font-face {
  font-family: 'Gotham Pro';
  font-style: bold;
  font-weight: 700;
  src: local('Gotham Pro Bol'), url('../fonts/GothaProBol.woff') format('woff');
}
